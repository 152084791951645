<template>
  <div class="app-meals">
    <category-ing-top-panel @create-meal="openModal" />
    <category-ing-table />
    <category-ing-modal :show.sync="modal.show" :title="modal.title" />
  </div>
</template>

<script>
import CategoryIngTable from './categoryIng-table/categoryIng-table.vue';
import CategoryIngModal from './categoryIng-modal/categoryIng-modal.vue';
import { FETCH_CAT_ING } from './shared/state/types/action_types';
import CategoryIngTopPanel from './categoryIng-top-panel/categoryIng-top-panel.vue';

export default {
  name: 'CategoryIngredients',
  components: { CategoryIngTable, CategoryIngModal, CategoryIngTopPanel },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить категорию приема пищи',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(FETCH_CAT_ING, { page: 1 });
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
  },
};
</script>
