<template>
  <div class="meals-top-panel">
    <div class="is-flex is-justify-content-flex-end">
      <div class="add-meal">
        <el-button type="primary" icon="el-icon-plus" @click="openModal">
          Добавить категорию ингредиента
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryIngTopPanel',
  methods: {
    openModal() {
      this.$emit('create-meal');
    },
  },
};
</script>
